import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import * as Yup from "yup";
import { getActiveOrganization, getAllUsersData } from "selectors/organizations";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import Constants from "const/Constants";
import FormLabel from "nlib/common/FormLabel";
import Input from "nlib/ui/Input";
import Modal, { ModalFooter } from "nlib/ui/Modal";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import Tooltip from "nlib/ui/Tooltip";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";

const EMAIL_VALIDATION_SCHEMA = Yup.object().shape({
  userEmail: Yup.string().trim().email().required()
});

const PHONE_VALIDATION_SCHEMA = Yup.object().shape({
  userPhone: Yup.string().trim().matches(Constants.PHONE_INPUT_PATTERN)
});

const AskClientWindow = ({ transactionsCount, noPhone, onClose, ...restProps }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const usersData = useSelector(getAllUsersData);

  const { guestClientsOnly, countryCode } = useSelector(getActiveOrganization);

  const selectedBusinessData = useSelector(getSelectedBusinessData);

  const [userEmail, setUserEmail] = useState("");

  const [userPhone, setUserPhone] = useState("");

  const smsNotificationsAllowed = Constants.SMS_NOTIFICATIONS_ALLOWED_COUNTRIES
    .includes(countryCode);

  const accountantUsersData = useMemo(() => {
    return usersData.filter(({ role }) => {
      return UserRoles.checkIsAccountant(role);
    });
  }, [usersData]);

  const businessUsersData = useMemo(() => {
    return usersData.filter(({ role, guestUser, businessIds }) => {
      return UserRoles.checkIsBusiness(role) && !guestUser
        && (!businessIds.length || businessIds.includes(selectedBusinessData.id));
    });
  }, [usersData, selectedBusinessData]);

  const guestUsersData = useMemo(() => {
    return usersData.filter(({ role, guestUser, businessIds }) => {
      return UserRoles.checkIsBusiness(role) && guestUser
        && (!businessIds.length || businessIds.includes(selectedBusinessData.id));
    });
  }, [usersData, selectedBusinessData]);

  const accountantUserEmail = useMemo(() => {
    return accountantUsersData.some(({ email }) => {
      return email && userEmail && email.trim().toLowerCase() === userEmail.trim().toLowerCase();
    });
  }, [userEmail, accountantUsersData]);

  const uniqueBusinessUserEmail = useMemo(() => {
    return !businessUsersData.some(({ email }) => {
      return email && userEmail && email.trim().toLowerCase() === userEmail.trim().toLowerCase();
    });
  }, [userEmail, businessUsersData]);

  const validEmail = useMemo(() => {
    return EMAIL_VALIDATION_SCHEMA.isValidSync({ userEmail }) && !accountantUserEmail && uniqueBusinessUserEmail;
  }, [userEmail, accountantUserEmail, uniqueBusinessUserEmail]);

  const validPhone = useMemo(() => {
    return !userPhone || ((PHONE_VALIDATION_SCHEMA.isValidSync({ userPhone })) && !!Utils.normalizePhoneNumber(userPhone));
  }, [userPhone]);

  const handleEmailInputChange = useCallback((value) => {
    setUserEmail(value);
  }, []);

  const handleGuestEmailLinkClock = useCallback(({ target: { dataset: { value } } }) => {
    setUserEmail(value);
  }, []);

  const handlePhoneInputChange = useCallback((value) => {
    setUserPhone(value);
  }, []);

  const handleClose = useCallback(({ askClient, invite } = {}) => {
    if (askClient || invite) onClose({ userEmail, userPhone, invite: !!invite });
    else onClose();
  }, [userEmail, userPhone, onClose]);

  const businessUserNames = useMemo(() => {
    return businessUsersData.map((user, index) => {
      return (
        <Fragment key={user.id}>
          <span>{user.fullName || user.email}</span>
          {index !== businessUsersData.length - 1 && <span>, </span>}
        </Fragment>
      );
    });
  }, [businessUsersData]);

  const guestUserNames = useMemo(() => {
    return guestUsersData.map((user, index) => {
      return (
        <Fragment key={user.id}>
          <a data-value={user.email} onClick={handleGuestEmailLinkClock}>{user.email}</a>
          {index !== guestUsersData.length - 1 && <span>, </span>}
        </Fragment>
      );
    });
  }, [guestUsersData, handleGuestEmailLinkClock]);

  const handleAskClientButtonClick = useCallback(() => {
    handleClose({ askClient: true });
  }, [handleClose]);

  const handleInviteButtonClick = useCallback(() => {
    handleClose({ invite: true });
  }, [handleClose]);

  const handleCancelButtonClick = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const handleModalClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal
      {...restProps}
      className={Css.askClientWindow}
      contentClassName={Css.contentClassName}
      dialogClassName={Css.dialogClassName}
      iconComponent={Icons.User}
      title={uiTexts.inviteUser}
      onClose={handleModalClose}>
      {!!transactionsCount && (
        <div className={Css.text}>
          <Icons.Info className={CommonCss.warningText} />
          <span>
            <b>{Utils.replaceTextVars(messages.transactionsToClarify, { transactionsCount })}</b>
            <div>{smsNotificationsAllowed ? messages.askClientDescription : messages.askClientByEmailDescription}</div>
          </span>
        </div>
      )}
      <div className={Css.text}>
        <span>
          <b>{`${uiTexts.businessName}: `}</b>
          <span>{selectedBusinessData.name}</span>
        </span>
      </div>
      {!!businessUserNames.length && (
        <div className={Css.text}>
          <span>
            <b>{`${uiTexts.businessUsers}: `}</b>
            <span>{businessUserNames}</span>
          </span>
        </div>
      )}
      {!!guestUserNames.length && (
        <div className={Css.text}>
          <span>
            <b>{`${uiTexts.guestUsers}: `}</b>
            <span>{guestUserNames}</span>
          </span>
        </div>
      )}
      <br />
      <div className={Css.inputGroup}>
        {!noPhone && <FormLabel>{uiTexts.email}</FormLabel>}
        <div className={Css.inputWrap}>
          <Input
            type="email"
            value={userEmail}
            invalid={!validEmail}
            valid={!!userEmail && validEmail}
            placeholder={uiTexts.enterEmail}
            onChange={handleEmailInputChange} />
          <Tooltip opened={accountantUserEmail || !uniqueBusinessUserEmail}>
            <div className={CommonCss.negativeText}>
              <Icons.Warning />
              <span>{accountantUserEmail ? messages.userIsAccountant : messages.userAlreadyMember}</span>
            </div>
          </Tooltip>
        </div>
      </div>
      {smsNotificationsAllowed && !noPhone && (
        <>
          <div className={Css.text}>{messages.askClientPhone}</div>
          <div className={Css.inputGroup}>
            <FormLabel>{uiTexts.phone}</FormLabel>
            <Input
              type="tel"
              value={userPhone}
              invalid={!validPhone}
              valid={!!userPhone && validPhone}
              placeholder={uiTexts.enterPhoneNumber}
              onChange={handlePhoneInputChange} />
          </div>
        </>
      )}
      <ModalFooter className={Css.footer}>
        <Button
          large warning
          disabled={!validEmail}
          onClick={handleAskClientButtonClick}>
          {uiTexts.askWithMagicLink}
        </Button>
        {!guestClientsOnly && (
          <Button
            large primary
            disabled={!validEmail}
            onClick={handleInviteButtonClick}>
            {uiTexts.inviteAsBusinessUser}
          </Button>
        )}
        <Button large outline onClick={handleCancelButtonClick}>
          <span>{uiTexts.close}</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(AskClientWindow);
